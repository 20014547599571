/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import clockIcon from 'img/icon-clock.svg'

// Elements
import { SEO, ParseContent } from 'components/shared'

// Components
import Layout from 'components/layout/Layout'

// Blog
import Blog from 'components/related/Blog'
import BlogIk, { BlogConsumer } from 'components/shared/BlogIk'
import Plaatjie from '@ubo/plaatjie'

const Hero = styled.section`
  @media screen and (max-width: 992px){
    background-size: cover!important;
  }
`

const HeroContent = styled.div`
  padding-top: 154px;
  padding-bottom: 260px;
  padding-left: 50px;
  padding-right: 50px;
  color: white;
  position: relative;
`
const Content = styled.div`
  margin-top: -200px;

`
const HeroImage = styled.div`
  /* position: absolute; */
  border-radius: 10px;
  background-size: cover;
  width: calc(100% - 100px);
  margin-left: 50px;
  
  @media (min-width: 992px) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 992px){
    margin-left: 0;
    width: 100%;
  }
`
const Title = styled.div`
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  padding: 20px;
  font-size: ${(props) => props.theme.font.size.l};
  line-height: ${(props) => props.theme.font.size.xxl};
  font-weight: ${(props) => props.theme.font.weight.l};

  display: flex;
  background : white;
  position: relative;
  margin-top: -30px;
  color: ${(props) => props.theme.color.text.third};

  .case{
    color: ${(props) => props.theme.color.text.secondary};
  }

  @media screen and (max-width: 992px){
    font-size: 12px;
    line-height: 20px;
  }
`

const Time = styled.div`
  background: #012E58;
  padding: 8px 10px;
  color: white;
  border-radius: 15px;
  display: flex;
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.secondary};
  letter-spacing: .7px;
  width: fit-content;
  position: relative;
  margin-top: -15px;
  z-index: 2;

  @media (min-width: 992px) {
    margin-left: 70px;
    bottom: 15px;
  }

  @media (max-width: 991px) {
    margin-left: 50px;
    bottom: 20px;
  }
`

const TextContent = styled.div`
  
`

const BlogTitle = styled.h1`
  font-size: ${props => props.theme.font.size.l};
  font-weight: ${props => props.theme.font.weight.l};
  line-height: ${props => props.theme.font.size.xxl};

  @media (max-width: 991px){
    font-size: ${props => props.theme.font.size.s};
    line-height: ${props => props.theme.font.size.xm};
  }
`

const BannerImage = styled(Plaatjie)`
  width: 100%;
  height: 300px;

  & img {
    border-radius: 10px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, categories, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.media.banner_image.localFile &&
            acf.media.banner_image.localFile.childImageSharp.openGraph.src,
        }}
        type="article"
      />

      <Hero className="hero-image-small" style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}>
        <HeroContent />
      </Hero>

      <Content className="container position-relative">
        <HeroImage>
          <BannerImage image={acf.media.banner_image}  alt={title} />
          <Time>
            <img src={clockIcon} alt='clock' />
            <span className='mx-2'>
              {acf.meta.readtime}
            </span>
          </Time>
          <Title className="mx-3 mx-lg-5">
            {categories && (
              <span className='case mr-2 d-flex'>
                <span>
                  {categories.map(({ name }, index) => `${index > 0 ? ', ' : ''}${name}`)}
                </span>
                <span className="d-inline-block pl-2">
                  |
                </span>
              </span>
            )}
            <BlogTitle className="mb-0">
              {title}
            </BlogTitle>
          </Title>
        </HeroImage>

        <div className="row pb-5 mt-5 mt-lg-0">
          <div className='col-lg-1' />
          <TextContent className='col-lg-8'>
            <ParseContent content={acf.meta.description} />
          </TextContent>
        </div>

        {acf.related && (
          <div className="container my-5">
            <BlogIk
              settings={{
                limit: 3,
                postIds: acf.related
              }}
            >
              <BlogConsumer>
                {({ hasPosts, posts }) => {
                  return (
                    <div>
                      {hasPosts && (
                        <Blog posts={posts} />
                      )}
                    </div>
                  )
                }}
              </BlogConsumer>
            </BlogIk>
          </div>
        )}
      </Content>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      categories {
        name
      }

      acf {
        media {
          banner_image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        meta {
          readtime
          intro
          description
        }

        banner {
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                  src
                }
              }
            }
          }
        }

        related
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate